@import url('https://fonts.googleapis.com/css2?family=Orelega+One&display=swap');

@font-face {
  font-family: 'museo_slab700';
  src: url('fonts/museo_slab_4-webfont.woff2') format('woff2'),
    url('fonts/museo_slab_4-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #F5F6F7;
  font-family: 'Orelega One', cursive;
}

label {
  color: #56535B;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  /* 142.857% */
}


.h-100vh {
  min-height: 100vh;
}

.login_area {
  padding-left: 100px;
}

.login_area p {
  color: #56535B;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Orelega One', cursive;
  font-size: 24px;
  font-style: normal;
  line-height: 30px;
  /* 125% */
  text-transform: capitalize;
}

input:focus,
input:active,
input:hover {
  outline: none;
  background-color: #ffffff;
  background: #ffffff;
}

input {
  border-radius: 8px;
  border: 0.8px solid #CA92EC;
  background-color: #FFF;
  box-shadow: 0px 44px 92px 0px rgba(159, 159, 159, 0.25);
  display: flex;
  padding: 10px 14px 10px 15px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  gap: 197px;
  width: 100%;
  font-family: 'museo_slab700';
  font-size: 14px;

}

button:focus,
button:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border: none;
}

.site_btn button {
  background-color: #CA92EC;
  color: #ffffff;
  border: 1px solid #CA92EC;
  width: 100%;
  height: 50px;
  border-radius: 15px;
}

.search_icon {
  fill: rgba(79, 84, 84, 0.5);
}

.form-group.search_input input {
  padding-left: 45px;
}

span.search_icons {
  position: absolute;
  top: 8px;
  left: 15px;
}

.center_img img {
  width: 75%;
}

.forget a,
.forget button {
  color: #56535B;
  text-decoration: none;
  font-family: 'museo_slab700';
  font-size: 14px;
}

.inner_logo img {
  max-width: 130px;
}

.header {
  padding: 15px;
  border-bottom: 1px solid rgba(86, 83, 91, 0.20);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F5F6F7;
  position: sticky;
  top: 0;
  z-index: 2;
  border-right: 1px solid rgba(86, 83, 91, 0.20);
  border-left: 1px solid rgba(86, 83, 91, 0.20);
}

.sideBar {
  border-right: 1px solid rgba(86, 83, 91, 0.20);
}

.w-400 {
  max-width: 320px;
  width: 100%;
}

button {
  border: none;
  outline: none;
}

.profile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile img {
  max-width: 30px;
}

.dropdown-toggle {
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 14px;
  background: transparent;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu-end {
  top: 15px !important;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  border: none;
}

.norification {
  padding: 20px 15px;
}

.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 996;
  transition: all 0.3s;
  padding: 0 20px;
  overflow-y: auto;

  scrollbar-color: #aab7cf transparent;
}

main {
  transition: all 0.3s;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

.menu_title {
  color: rgba(86, 83, 91, 0.80);
  font-size: 12px;
  font-family: 'museo_slab700';
  margin-bottom: 24px;
  margin-top: 24px;
}

.sidenav {
  padding: 0 15px;
  list-style: none;
}

.sidenav .nav-item a {
  border-radius: 3px;
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  padding: 15px 0;
  margin: 15px 0;
  transition: 0.3s;
}

.sidenav .nav-item a.active {
  color: #CA92EC;
  box-shadow: -2px 0px 0px 0px #CA92EC;
  background: rgba(202, 146, 236, 0.10);
  padding: 15px;

}

.sidenav .nav-item a:hover {
  color: #CA92EC;
  box-shadow: -2px 0px 0px 0px #CA92EC;
  background: rgba(202, 146, 236, 0.10);
  padding: 15px;

}

.sidenav .nav-item span {
  padding-right: 10px;
}

.main {
  margin-left: 300px;
}

.edit_btn {
  color: #CA92EC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  font-family: 'museo_slab700';
  border-radius: 4px;
  background: rgba(202, 146, 236, 0.10);
  padding: 10px;
  gap: 5px;
}

.sidetoggle .main {
  margin-left: 0;
}

.sidetoggle .sidebar {
  margin-left: -300px;
}

.toggle_logo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.setBorder {
  position: relative;
}

.setBorder::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: 0px;
  background: rgb(0 0 0 / 15%);
  top: 0;
}

.setBorder::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  right: 0px;
  background: rgb(0 0 0 / 15%);
  top: 0;
}

.all_comp {
  padding: 20px 30px;
}

.pagetitle {
  color: #56535B;
  font-family: 'Orelega One', cursive;
  font-size: 24px;
  font-style: normal;
  line-height: 20px;
}

.pagetitle button,
.pagetitle a {
  color: #56535B;
  font-family: 'Orelega One', cursive;
  font-size: 24px;
  font-style: normal;
  line-height: 20px;
  background-color: transparent;
  gap: 10px;
  text-decoration: none;
}

.card {
  border-radius: 16px;
  background: #E3F5FF;
  border: 0;
}

.card-title {
  color: #56535B;
  font-family: 'Orelega One', cursive;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.card .numbers {
  font-size: 26px;
  color: #56535B;
  font-family: 'museo_slab700';
  font-style: normal;
  line-height: 36px;
}

.card .graph {
  font-size: 12px;
  color: #56535B;
  font-family: 'museo_slab700';
  font-style: normal;
  line-height: 20px;
}

.card-body {
  padding: 20px 30px;
}

.notify_title {
  color: #56535B;
  font-family: 'Orelega One', cursive;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.notification_list {
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

.n_item {
  color: #56535B;
  font-size: 14px;
  font-style: normal;
  line-height: 17px;
  padding-bottom: 10px;
  
}

.n_time {
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;

}

.norification {
  padding: 20px 30px;
  height: 100vh;
  overflow: auto;
  position: fixed;
}

.norification::-webkit-scrollbar {
  width: 5px;
}
 
.norification::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.norification::-webkit-scrollbar-thumb {
  background-color: #e2ccf0;
  outline: 1px solid rgb(205, 206, 207);
  height: 200px;
  cursor: pointer;
}
.notification_list li {
  border-bottom: 1px solid rgb(0 0 0 / 15%);
  padding: 10px 0 20px 0;
}

.table th {
  color: #584278;
  font-family: 'Orelega One', cursive;
  font-size: 14px;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 400;
}

.table thead {
  border-radius: 0px 0px 16px 16px;
  --bs-table-bg: rgba(202, 146, 236, 0.10);
}

.table>:not(caption)>*>* {
  padding: 20px 15px;
}

.table_profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table tr td {
  padding: 12px;
}

.table tr th {
  white-space: nowrap;
}

.table_profile img {
  max-width: 30px;
}

.table {
  border-radius: 15px;
  overflow: hidden;
}

.tname,
td {
  color: #56535B !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.26px;
  text-transform: capitalize;
  font-family: 'museo_slab700';
  vertical-align: middle;
}

.tname a {
  color: #56535B;
}

.actions .btn {
  margin: 0 2px;
  padding: 0;
}

.actions .view {
  color: #20C997;
  padding: 0px;
}

.actions .block {
  color: #992f0f;
}
.actions .blockgreen {
  color: #3f8b21;
}
.actions .edit svg path{
  fill: currentColor;
}
.actions .edit {
  color: #5A9BD4;
}
.actions .archive {
  color: #FD7E14;
}
.actions .archive svg {
  width: 14px; height: 14px;
}
.actions .edit svg {
  fill: currentColor;
  width: 15px;
  height: 15px;
}

.action .btn {
  margin: 0 2px;
  padding: 0;
}
.action .block {
  color: #992f0f;
}
.action .blockgreen {
  color: #3f8b21;
}

.actions .delete {
  color: #FF4B26;
}

.action button {
  background-color: transparent;
}

.actions button {
  background-color: transparent;
}

.outer_div {
  border-radius: 16px;
  border: 1px solid rgba(86, 83, 91, 0.20);
  background: #FFF;
  padding: 30px;
}

.profile_image img {
  width: 100px;
}

.small_heading {
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.6;
}

.iconCursor{
  cursor: pointer;
}

.small_heading p {
  font-size: 11px;
  line-height: 14px;
  color: rgba(86, 83, 91, 1);
}

.name {
  color: #56535B;
  font-family: 'museo_slab700';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  word-wrap: break-word;
  
}

.profile_list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.profile_list li {
  margin-bottom: 30px;
}

.card_outer {
  border-radius: 10px;
  border: 1px solid rgb(86 83 91 / 0.10);
  background: #FFF;
  padding: 15px;
  margin-top: 20px;
}

.service_name {
  color: #584278;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-transform: capitalize;
  font-family: 'museo_slab700';
}

.card_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service_status {
  font-family: 'museo_slab700';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 0px 0px;
}

.card_outer .name {
  padding: 10px 0;
}

.card_outer .small_heading {
  padding: 2px 0;
}

.sub_title {
  color: #56535B;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-family: 'Orelega One', cursive;
  text-transform: capitalize;
}

.complete {
  color: #0F993E;
}

.cancel {
  color: #FF4B26;
}

.border-bottom {
  margin-bottom: 40px;
}

.blank_btn {
  background-color: transparent;
}

.decline,
.accept {
  font-family: 'museo_slab700';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.26px;
  text-transform: capitalize;
}

.decline {
  color: #FF4B26;
}

.accept {
  color: #0F993E;
}

.two_column {
  list-style: none;
  column-count: 2;
}

ul.two_column li {
  width: 100%;
  margin-bottom: 25px;
  break-inside: avoid;
}



.doc_list img {
  border-radius: 10px;
  margin-bottom: 8px;
  min-width: 96px;
}

a {
  text-decoration: none;
}

.doc_gallery {
  display: flex;
  gap: 15px;
  overflow-x: auto;
}

.doc_gallery::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #fff;
}

.doc_gallery::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

.table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #fff;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

.rating_outer {
  border-radius: 10px;
  border: 1px solid rgba(86, 83, 91, 0.10);
  background: #FFF;
  padding: 15px;
}

.rating_outer_sec {
  border-radius: 10px;
  border: 1px solid rgba(86, 83, 91, 0.10);
  background: #FFF;
  padding: 15px;
}

.rating_outer ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;

}

.num_point {
  color: #56535B;
  font-size: 50px;
  font-style: normal;
  font-family: 'museo_slab700';
  font-weight: bold;
  line-height: normal;
}

.num_point sub {
  font-size: 20px;
  font-weight: 400;
  right: 12px;
}

.rating_outer {
  margin-top: 20px;
}

.stars {
  color: #F5C342;
  white-space: nowrap;
  overflow: hidden;
}

.stars svg {
  margin-right: 5px;
}

.rating_numbers {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.rating_numbers li {
  margin-bottom: 15px;
}

.rating_numbers li .count_star {
  color: #56535B;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  font-family: 'museo_slab700';
  line-height: normal;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.rating_numbers li .count_star span {
  display: block;
  height: 5px;
  background-color: #F5C342;
  margin-left: 5px;
  border-radius: 33px;
}

.rting_star svg {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.rting_star {
  color: #F5C342;
}

.img_user img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.dropdown-item {
  color: #56535B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: 'museo_slab700';
  line-height: normal;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.status_action button {
  background-color: transparent;

}

.modal-header {
  border-bottom: 0;
}

.status_action button {
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  font-size: 14px;
}

.modal_icon svg {
  width: 30px;
  height: 30px;
}

.modal_msg {
  color: #56535B;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-family: 'Orelega One';
  margin-top: 20px;
}

.modal_btns {
  margin-top: 30px;
}

.modal-body {
  padding-top: 25px;
  padding-bottom: 25px;
}

.modal_btns {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.modal_btns button,.purple_btn .btns {
  border-radius: 11px;
  background: transparent;
  text-align: center;
  font-size: 14px;
  color: #56535B;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  min-height: 36px;
  min-width: 112px;
  font-family: 'Orelega One';
  line-height: 30px;
}

.modal_btns .purple,.purple_btn .btns {
  background: #CA92EC;
  color: #fff;
  transition: 0.3s ease-in-out;
}
.modal_btns .purple:hover,.purple_btn .btns:hover{
  background: #0F993E;
}

.red {
  color: #FF4B26;
}

.green {
  color: #0F993E;
}

.single_column {
  list-style: none;
}

.single_column li {
  margin-bottom: 25px;
}

.border_right {
  position: relative;
}

.border_right:after {
  position: absolute;
  content: "";
  top: 15px;
  height: 120px;
  right: 0;
  border-radius: 8px;
  border: 1px solid rgba(86, 83, 91, 0.10);
  background: #FFF;
}

.ps_100 {
  padding-left: 100px;
}

.light_gray {
  padding: 24px;
  border-radius: 16px;
  background: #E5ECF6;
  height: 100%;
}

.skyblue {
  border-radius: 16px;
  background: #E3F5FF;
  padding: 24px;
  height: 100%;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
}


.styled-checkbox+label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
}


.styled-checkbox:hover+label:before {
  background: rgba(202, 146, 236, 1);
}


.styled-checkbox:focus+label:before,
.styled-checkbox+label:before {
  border-radius: 5px;
  border: 1px solid rgba(86, 83, 91, 0.30);
}


.styled-checkbox:checked+label:before {
  background: rgba(202, 146, 236, 1);
}

.styled-checkbox:checked+label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow:
    2px 0 0 white,
    4px 0 0 white,
    4px -2px 0 white,
    4px -4px 0 white,
    4px -6px 0 white,
    4px -8px 0 white;
  transform: rotate(45deg);
}

.unstyled {
  padding: 0;
  list-style: none;
}

.checkboxes label {
  display: flex;
  align-items: center;
}

.checkboxes li {
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(86, 83, 91, 0.10);
  padding-top: 25px;
}

.checkboxes li:first-child {
  padding-top: 15px;
}

.selector_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkboxes {
  position: relative;
}

.form-select {
  min-width: 214px;
  border-radius: 10px;
  color: #56535B;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  font-family: 'museo_slab700';
  height: 43px;
}
.announcement-send{
  color: #56535B;
  text-decoration: underline;
  padding-right: 60px;
}

.errText {
  font-size: 15px;
  color: red;
  margin-left: 8px;
}

select option {
  padding: 15px;
}

.btns {
  border-radius: 11px;
  background: #0F993E;
  color: #FFF;
  font-family: 'museo_slab700';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  text-transform: capitalize;
  min-height: 36px;
  min-width: 236px;
  transition: 0.3s ease-in-out;
}
.btns:hover{
  background: #CA92EC;
}

.loader{
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rounded-circle{
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
.editBtn {
  background-color: #f1ecf6;
  padding: 6px;
  border-radius: 5px;
  font-size: 14px;
  color: #ca92ec;
  cursor: pointer;
  text-align: center;
  border: 1px solid #CA92EC;
  transition: ease 0.3s;
}
.editBtn:hover{
  background-color: #CA92EC;
  color: #fff;
}

.whiteProfilePage {
  border-radius: 16px;
  border: 1px solid rgba(86, 83, 91, 0.2);
  background: #fff;
  padding: 30px;
  margin-top: 25px;
}

.profileDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}

.profileDetails .details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
}

.details .inputSet {
  display: flex;
  flex-direction: column;
}

.details .inputSet h6 {
  color: #7d7c7e;
  font-family: "museo_slab700";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.details .inputSet h5 {
  color: rgba(86, 83, 91, 1);
  font-family: "museo_slab700";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px; /* 121.429% */
}


.imageSet{
  position: relative;
}

.imageChnage{
  position: absolute;
  top: 53px;
  right: 0;
}

.adminProfilePic{
  width: 80px;
  height: 80px;
  border-radius: 100px;
}

.customerProfilePic{
  width: 80px;
  border-radius: 100px;
}


.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
  position: absolute;
}

@keyframes shimmer {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}
a{
  color: #56535B !important;
}
.errorMessage{
  font-size: 13px;
  font-weight: 500;
  color: red;
}

.badge{
  font-weight: 300;
  font-size: 12px;
}

.emptyData {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 60vh;
  justify-content: center;
  align-items: center;
  color: #56535B;
}

.emptyData img {
  width: 100px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px;
}

.emptyData h4 {
  font-size: 24px;
  color: #CA92EC;
}

.emptyData p {
  font-size: 14px;
  color: #56535B;
}

.paginationIcon{
  cursor: pointer;
}





.addressSearchBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px 13px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  background: #6E6D70;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #DFDEDF;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.addressSearchBtn:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}